// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap

// https://coolors.co/palette/03045e-023e8a-0077b6-0096c7-00b4d8-48cae4-90e0ef-ade8f4-caf0f8

$primary: #0077B6;
$secondary: #0096C7;

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';


